
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from "vue"
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatToDatatable } from "@/common/formaterToDatatable";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { getModule } from "vuex-module-decorators";
import { translate } from "@/core/plugins/i18n";
import { useRoute, useRouter } from "vue-router";
import CashModule from "@/store/modules/sales copy/modules/cash";
import FilterOpeningList from "@/models/cash/FilterOpeningList";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import { boolean, number, string } from "yup/lib/locale";
import toCurrency from "@/core/filters/ToCurrency"

export default defineComponent({
    components:{
         ModuleMaster
        , Datatable
        , Datarow
        , StatisticsWidget5
    },
    setup() {
        //Propiedades
        const moduleCash = getModule(CashModule);

        const router = useRouter();
        const formSearch = ref();
        const route = useRoute()
        const idParam = ref('');
        const paramClose =  ref(0);
        /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "paymentType", HeadLabel: "Tipo de Pago", Type: "text", VisibleInGrid: true },
            { PropName: "chargeReference", HeadLabel: "Referencia", Type: "text", VisibleInGrid: true },
            { PropName: "saleInvoince", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "currency", HeadLabel: "Tipo de Moneda", Type: "text", VisibleInGrid: true },
            { PropName: "customer", HeadLabel: "Cliente", Type: "text", VisibleInGrid: true },
            { PropName: "amount", HeadLabel: "Importe", Type: "moneda", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total de Compra", Type: "moneda", VisibleInGrid: true },            
        ]);

        const labels = reactive({
            placeHolderToFieldSearch: "Aprovar Cierre de Caja",
            titleNewCrudAction: "Aprovar Cierre de Caja",
        });

        //METODOS
        const handleChangePage = (page: number) => {
            moduleCash.CHANGE_PAGE({ currentPage: page });
        }

        const handleCrudAction = (action: string | number = "N", id: string) => {
            switch(action){
                case "A":
                    sendApprovalClosed(idParam.value)
                    break;
                default:
                    break;
            }
        }

        const sendApprovalClosed = (id: string) => {
            Swal.fire({
                    title: `¿Está seguro de aprobar el cierre de caja?`,
                    text: `Se procederá a aprobar el cierre de caja y no podrá revertir la acción`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#000000',
                    cancelButtonColor: '#7E829',
                    confirmButtonText: 'Si, aprobar',
                    cancelButtonText: 'Cancelar'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            moduleCash.APPROVAL_CLOSED(id);
                        }
                    });
        }

        const searchFilter = (data: FilterOpeningList) => {
            moduleCash.UPDATE_FILTERS(data)
        }

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(moduleCash.recordsSalesCharges, configTable);
        });

        const records = computed(() => moduleCash.recordsSalesCharges);
        const totalRecords = computed(() => moduleCash.totalRecordsSalesCharges);
        const sizePage = computed(() => moduleCash.paginationSalesCharges.pageSize);
        const lstCash = computed(() => moduleCash.getCashRegisters);
        const salesChargesTotalData = computed(() => moduleCash.salesChargesTotal);
        
        onMounted(() => {
                setCurrentPageBreadcrumbs(translate('BREADCRUMB.MOVEMENTSCASH'), [translate('BREADCRUMB.MODULES')]);
                console.log(route.query.nuevoparametro)
                
                paramClose.value = parseInt(route.query.nuevoparametro?.toString() ?? '0'); //== undefined ? 0 : parseInt(route.query.nuevoparametro ,?? 0);
                idParam.value = (route.params.id as string);
                // return this.$route.params.id;
                if(idParam.value != ''){
                    moduleCash.UPDATE_FILTERS_CHARGES( {
                        guid: idParam.value
                    });

                    moduleCash.GET_SALES_CHARGES_TOTAL(idParam.value);
                }
        });

        return {
             labels
            , headers
            , renderRows
            , lstCash
            , totalRecords
            , records
            , sizePage
            , router
            , formSearch
            , paramClose
            , salesChargesTotalData
            
            , toCurrency
            , searchFilter
            , handleChangePage
            , handleCrudAction
            , sendApprovalClosed
        }
    }
})
