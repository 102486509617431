import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05b5f0d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-body p-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("a", {
    href: "#",
    class: _normalizeClass([[_ctx.widgetClasses, `bg-${_ctx.color}`], "card hoverable p-0"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass([`svg-icon-${_ctx.iconColor}`, "svg-icon svg-icon-2x mx-n1"])
      }, [
        _createVNode(_component_inline_svg, { src: _ctx.svgIcon }, null, 8, ["src"])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bolder fs-2"])
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("div", {
        class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bold fs-7"])
      }, _toDisplayString(_ctx.description), 3)
    ])
  ], 2))
}